<template>
    <div style="margin:5px;" class="rowTitle">
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
                {{$parent.$parent.lang.card_expenses}} </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <b-card-text> 
                        <v-simple-table striped hover style="width:100%;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>{{$parent.$parent.lang.item_code}}</th>
                                        <th style="width:40%;">{{$parent.$parent.lang.description}}</th>
                                        <th>{{$parent.$parent.lang.qtty}}</th>
                                        <th>{{$parent.$parent.lang.item_price}}</th>
                                        <th>{{$parent.$parent.lang.total}}</th>
                                        <th>{{$parent.$parent.lang.action}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in exprows" :key="index">
                                        <td>{{item.itemid}}</td>
                                        <td>{{item.description}}</td>
                                        <td>{{item.qty}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.total}}</td>
                                        <td>
                                            <v-btn class="ma-1" style="height:25px;background:red;color:#FFF;width:70px"  @click="DeleteLabor( item.id,index )">{{$parent.$parent.lang.delete}}</v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>{{$parent.$parent.lang.totals}}</th>
                                        <th>{{exptotals}}</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-input v-model="itemid" id="_id" @change="getProducts()" style="font-size: 0.8rem;width:100px"></b-input>
                                        </td>
                                        <td>
                                            <b-input v-model="description" id="_description" style="font-size: 0.8rem"></b-input>
                                        </td>
                                        <td>
                                            <b-input v-model="qty" style="font-size: 0.8rem"></b-input>
                                        </td>
                                        <td>
                                            <b-input v-model="price" style="font-size: 0.8rem"></b-input>
                                        </td>
                                        <td>
                                            <b-input v-model="total" readonly style="font-size: 0.8rem"></b-input>
                                        </td>
                                        <td>
                                            <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="addexpens()">{{$parent.$parent.lang.add}}</v-btn>
                                        </td>
                                    </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
                {{$parent.$parent.lang.other_expenses}} </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <b-card-text> 
                        <v-simple-table striped hover style="width:100%;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th style="width:40%;">{{$parent.$parent.lang.description}}</th>
                                        <th>{{$parent.$parent.lang.item_price}}</th>
                                        <th>{{$parent.$parent.lang.payment_method}}</th>
                                        <th>{{$parent.$parent.lang.action}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in otherexp" :key="index">
                                        <td>{{item.description}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.total}}</td>
                                        <td>
                                            <v-btn class="ma-1" style="height:25px;background:red;color:#FFF;width:70px"  @click="DeleteLabor( item.id,index )">{{$parent.$parent.lang.delete}}</v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>{{$parent.$parent.lang.totals}}</th>
                                        <th>{{exptotals}}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b-input v-model="other.description" id="_description" style="font-size: 0.8rem"></b-input>
                                        </td>
                                        <td>
                                            <b-input v-model="other.price" style="font-size: 0.8rem"></b-input>
                                        </td>
                                        <td>
                                            <v-select
                                            item-text="text"
                                            item-value="value"
                                            v-model="other.paytype"
                                            :items="payTypeOp"
                                            
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="addotherexp()">{{$parent.$parent.lang.add}}</v-btn>
                                        </td>
                                    </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            cardid: 0,
            itemid: '',
            description:'',
            price: '',
            qty: 1,
            exprows: [],
            otherexp: [],
            payTypeOp: [],
            other: {
                description: '',
                price: '',
                paytype: ''
            }
        }
    },
    computed: {
        total: function(){
            let t = 0;
            if(this.price == '' || this.qty == '')
            {
                return 0;
            }
            t = parseFloat(this.qty) * parseFloat(this.price);
            return this.$RoundNum(t);
        },
        exptotals: function(){
            let t = 0;
            for(let i=0;i<this.exprows.length;i++){
                t = parseFloat(t) + parseFloat(this.exprows[i].total);
            }
            return this.$RoundNum(t);
        }
    },
    created() {
        // this.getexpense(1);
        
        this.getPyamentType();

    },
    methods: {
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.payTypeOp.push({
                        text: this.$parent.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.other.paytype = res.results.data[0].id;
            })
        },
        DeletExpens(){
            //
        },
        addexpens(){
            let message = "";
            let vld = true;
            if(this.itemid == ''){
                message = 'يرجى ادخال رمز الصنف المعرف بالمخزون';
                vld = false;
            }
            if(this.description == ''){
                message = 'يرجى اضافة وصف للمصروفات';
                vld = false;
            }
            if(this.qty == '' || this.price == ''){
                 message = 'يرجى اضافة سعر وكمية للمصروفات';
                vld = false;
            }
            if(vld == false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addCardExpens");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            post.append('data[itemid]',this.itemid);
            post.append('data[description]',this.description);
            post.append('data[qty]',this.qty);
            post.append('data[price]',this.price);
            post.append('data[total]',this.total);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.getexpense(1)
                    this.itemid = '';
                    this.description = '';
                    this.price = '';
                    this.qty = 1;
                    // this.$parent.updateHome()
                    let message = 'تمت الاضافة بنجاح';
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
            )
        },
        addotherexp(){
             let message = "";
            let vld = true;
            if(this.other.description == ''){
                message = 'يرجى اضافة وصف للمصروفات';
                vld = false;
            }
            if(this.other.paytype == '' || this.other.price == ''){
                message = 'يرجى اضافة سعر للمصروفات';
                vld = false;
            }
            if(!vld){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addCardOExpens");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            post.append('data[description]',this.other.description);
            post.append('data[price]',this.other.price);
            post.append('data[paytype]',this.other.paytype);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.getexpense(1)
                    this.other.description = '';
                    this.other.price = '';
                    // this.$parent.updateHome()
                    let message = 'تمت الاضافة بنجاح';
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
            )
        },
        getexpense(type){
            const post = new FormData();
            post.append("type","getCardExpens");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            post.append('data[type]',type);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(type == 1)
                        this.exprows = res.results.data;
                    else if(type == 2)
                        this.otherexp = res.results.data;
                    
                    if(type == 1)
                        this.getexpense(2);
                }
                
            )
        },
        getProducts() {
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.itemid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].qty > 0){
                        this.itemid = response.data.results.data[0].itemcode;
                        this.description = response.data.results.data[0].name;
                        this.price = response.data.results.data[0].price;
                        this.qty = 1;
                        this.calckMe();
                    }else{
                        this.itemid = '';
                        this.description = '';
                        this.qty = '';
                        this.price = '';
                        this.total = '';
                        let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                    }
                    
                }else{
                    this.itemid = '';
                    this.description = '';
                    this.qty = '';
                    this.price = '';
                    this.total = '';
                    let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
                this.calckMe();
            })
        },
        getProducts2() {
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[item_number]',this.itemid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    this.itemid = response.data.results.data[0].itemcode;
                    this.description = response.data.results.data[0].name;
                    this.price = response.data.results.data[0].price;
                    this.qty = 1;
                    
                }
            })
        },
    },
}
</script>