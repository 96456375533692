<template>
    <div>
        <header-portrate />
    
    <div style="margint-top:10px;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" style="display:none;" />
        <div class="m-3">
            <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" id="underwork" @click="$router.push({name: 'Home',params: {select: '1',doit:'1'}})">{{lang.inprogress}}</b-button>
            <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" id="latecard" @click="$router.push({name: 'Home',params: {select: '4',doit:'3'}})">{{lang.late}}  </b-button>
            <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" id="closedcard" @click="$router.push({name: 'Home',params: {select: '3',doit:'2'}})">{{lang.closed}}</b-button>
            <b-button class="btn btn-sm btn-secondary mt-10" style="color:#fff;width:150px;margin-inline-end:10px;background:#000 !important;font-weight:bold;">{{lang.car_report}}</b-button>
        </div>
        <v-row class="mainrow" style="margin-left:20px !important;margin-right:20px !important;margin-top:-25px !important">
            <v-col
            cols="12"
            md="10"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" onfocus="(this.type='date')" style="background:#eee;direction:rtl" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" onfocus="(this.type='date')" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-form-input :placeholder="lang.mobile"  style="background:#eee;" v-model="mobile"></b-form-input>
                <b-form-input :placeholder="lang.plate_number" style="background:#eee;" v-model="plate_number"></b-form-input>
                <b-input-group-append style="border-left:1px solid gold">
                <b-button variant="light"
                style="background: gold !important;font-size:14px;color:#000;padding:8px 22px;border:1px solid #ccc;" @click="getCurrentCards()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
        </v-row>
        <v-row class="mt-3" style="margin-left:20px !important;margin-right:20px !important;">
            <v-col cols="12">
                <h6 class="h6title">{{lang.maintenance_cards}}</h6>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center">{{lang.cardid}}</th>
                            <th class="text-center">{{lang.plate_number}}</th>
                            <th class="text-center">{{lang.car_model}}</th>
                            <th class="text-center">{{lang.customer_name}}</th>
                            <th class="text-center">{{lang.mobile}}</th>
                            <th class="text-center">{{lang.received}}</th>
                            <th class="text-center">{{lang.delivered}}</th>
                            <th class="text-center">{{lang.spare_partes}}</th>
                            <th class="text-center">{{lang.total}}</th>
                            <th class="text-center">{{lang.vat}}</th>
                            <th class="text-center">{{lang.paid}}</th>
                            <th class="text-center">{{lang.remain}}</th>
                            <th class="text-center">{{lang.action}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tablerows" :key="index">
                            <td style="width:100px">{{ item.cardid }}</td>
                            <td>{{ item.plate_number }}</td>
                            <td>{{ item.car_model }}</td>
                            <td nowrap>{{ item.customer }}</td>
                            <td>{{ item.mobile }}</td>
                            <td nowrap>{{ item.date_in }}</td> 
                            <td nowrap>{{ item.date_out }}</td>
                            <td>{{ item.parts }}</td>
                            <td>{{ item.ftotal }}</td>
                            <td>{{ item.vat }}</td>
                            <td>{{ item.paid }}</td>
                            <td>{{ item.remaining }}</td>
                            <td style="width:50px;background:black;border-bottom:2px solid #fff !important;">
                                <v-btn style="width:50px;font-size:0.2em;background:black;box-shadow:none;color:#fff;height:25px !important;" v-b-toggle.view_card @click="ViewItem(item)">{{lang.view}}</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col cols="12">
                <h6 class="h6title">{{lang.invoices}}</h6>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center">{{lang.invoiceno}}</th>
                            <th class="text-center">{{lang.customer}}</th>
                            <th class="text-center">{{lang.mobile}}</th>
                            <th class="text-center">{{lang.plate}}</th>
                            <th class="text-center">{{lang.model}}</th>
                            <th class="text-center">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.total}}</th>
                            <th class="text-center">{{lang.vat}}</th>
                            <th class="text-center">{{lang.ftotal}}</th>
                            <th class="text-center">{{lang.paid}}</th>
                            <th class="text-center">{{lang.remain}}</th>
                            <th class="text-center">{{lang.action}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in invoicesrows" :key="index">
                            <td style="width:100px;text-align:center;">{{ item.invoice_number }}</td>
                            <td style="text-align:center;" nowrap>{{ item.full_name }}</td>
                            <td style="text-align:center;">{{ item.mobile }}</td>
                            <td style="text-align:center;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ item.plate_number }}</td>
                            <td style="text-align:center;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.parts">{{ item.model }}</td>
                            <td style="text-align:center;" nowrap>{{ item.inv_date.substr(0,10) }}</td>  
                            <td style="text-align:center;">{{ item.total }}</td>
                            <td style="text-align:center;">{{ item.vat }}</td>
                            <td style="text-align:center;">{{ item.ftotal }}</td>
                            <td style="text-align:center;">{{ item.paid }}</td>
                            <td style="text-align:center;">{{ item.remain }}</td>
                            <td style="text-align:center;background:#000;border-bottom:2px solid #FFF !important;width:50px;">
                            <v-btn style="font-size:0.2em;background:#000;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(item)">{{lang.view}}</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
    <Footer />
    <view-card ref="viewCard" />
    <popViewInvocie ref="popviewinv" />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ViewCard from '@/components/view-card.vue'
import PopViewInvocie from '@/components/popViewInvocie.vue';
import axios from 'axios'
export default {
  components: { breadCrumbs,TabsComp, Footer,HeaderPortrate,ViewCard,PopViewInvocie },
  data() {
    return {
          tablerows:[
          ],
          invoicesrows:[],
          sdate: '',
          edate: '',
          plate_number: '',
          mobile: '',
      }
  },
  methods: {
      ViewItem(item){
        this.$refs.viewCard.cardid = item.cardid;
        this.$refs.viewCard.card = item;
        this.$refs.viewCard.updateChileds();
        this.$refs.viewCard.calckInv();
      },
      getInvo(item){
          this.$refs.popviewinv.invid = item.id;
          this.$refs.popviewinv.invoice = item;
          this.$refs.popviewinv.getInvoice();
      },
      getCurrentInvoice(){
        
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        let post = new FormData();
        post.append('type','getAllInvoices');
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        post.append('auth',cook);
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
          invoice_number: this.invoice_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',data.plate_number);
        this.invoicesrows = [];
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
           // console.log("invoices",res);
          if(typeof res.error !== 'undefined' && res.error != null && typeof res.error.number !== 'undefined'
              && res.error.number != null && res.error.number == 200){
                this.invoicesrows = res.results.data;
              }
        })
      },
      getCurrentCards(){
        if(this.plate_number == '' && this.mobile == ''){
            return false
        }
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        const path = this.$router.currentRoute.path;
        let post = new FormData();
        post.append('type','getCardslist'); 
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',this.plate_number);
        this.tablerows = [];
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
          // console.log("corot",res);
          this.tablerows = res.results.data;
          this.getCurrentInvoice();
        })
      },
  },
  computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return  {
                text: this.lang.customers,
                disabled: true,
                href: '/clientList',
            };
        },
        tabs: function(){
            let name = "Maint. Requests";
            if(this.lang.langname == "ar"){
                name = "طلبات الصيانة";
            }
            return [
                    {
                        index:0,name:name,href:'/home',class:'mytab3',status:1
                    },
                    {
                        index:3,name:this.lang.car_report,href:'/car-report',class:'mytab',status:3
                    },
                ]
        }
  }
}
</script>