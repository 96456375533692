<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="publish_me" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="اصدار نسخة" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>اصدار نسخة</span>
                </div>
                <div @click="hide" id="hidePublish" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>اغلاق</span>
            </div>
            </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="4" sm="12">
                        <label>الدومين الفرعي</label>
                        <b-form-input class="inborder"
                            @keydown="checkLetter"
                            @change="checkLetter"
                            v-model="subdomain"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                         <label>اختر الدومين</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="domain"
                            :options="domainlist"
                            label="اختر الدومين "
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                         <label>تاريخ انتهاء النسخة</label>
                        <b-form-input class="inborder" type="date" v-model="expiredate"></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>اختر اللغة</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="language"
                            :options="langlist"
                            label="اختر اللغة "
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                    <v-col cols="12" md="8" sm="12">
                        <label>اختر النظام</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="systemtype"
                            :options="systemslist"
                            label="اختر النظام "
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                </v-row>
    </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addNote()" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            message: '',
            notify:1,
            orderid: 0,
            subdomain: '',
            domain: 'fatoorh.io',
            domainlist:[
                {text: 'e-carstech.com',value: 'e-carstech.com'},
                {text: 'samcsoft.com',value: 'samcsoft.com'},
                {text: 'fatoorh.io',value: 'fatoorh.io'},
                {text: 'samcotec.com',value: 'samcotec.com'},
            ],
            language: 'ar',
            langlist:[
                {text: 'اللغة العربية',value: 'ar'},
                {text: 'اللغة الانجليزية',value: 'en'},
            ],
            systemtype: 'invoice',
            systemslist:[
                {text: 'نظام الفوترة',value: 'invoice'},
                {text: 'نظام ادارة ورش السيارات',value: 'ecar'},
                {text: 'نظام الفوترة مع بيانات السيارة',value: 'ecinvoice'},
                {text: 'نظام ادارة علاقات العملاء',value: 'crm'},
                {text: 'نظام نقطة بيع التجزأة',value: 'posretail'},
                {text: 'نظام نقطة بيع المطاعم',value: 'pos'},
            ],
            expiredate: '',
        }
    },
    methods: {
        checkLetter(){
            const english = /^[A-Za-z0-9]*$/;
            let res = '';
            for(let i=0;i<this.subdomain.length;i++){
                if(english.test(this.subdomain[i])){
                    res = res + this.subdomain[i];
                }
            }
            this.subdomain = res;
        },
        addNote(){
            
            const post = new FormData();
            post.append("type" , "addDomain");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.orderid);
            post.append("data[subdomain]",this.subdomain);
            post.append("data[domain]",this.domain);
            post.append("data[language]",this.language);
            post.append("data[systemtype]",this.systemtype);
            post.append("data[expiredate]",this.expiredate);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                // console.log(res);
                let message = res.error.message;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                if(res.error.number == 200){
                    this.$parent.getOrder();
                    document.getElementById('hidePublish').click();
                }
                this.$parent.getOrder();
                this.$parent.updateChiled();
            });
            
            this.message = '';
        }
    },
    created() {
        //this.getNotes();
    },
}
</script>